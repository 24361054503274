<template>
  <div class="dark:bg-gray-800 rounded bg-gray-100 p-5">
    <h3 class="mb-5">Расчет платежей для физических лиц</h3>
    <div class="mb-5 flex">
      <p class="text-blue-500 mr-5">Курс евро: {{ eurRate }}</p>
      <p class="text-green-500">Курс доллара: {{ usdRate }}</p>
    </div>

    <div class="grid sm:grid-cols-1 lg:grid-cols-6 gap-2 items-end">
      <label>
        Тип ТС
        <select v-model="form.type" @change="onTypeChange">
          <option disabled selected value="">Выберите значение</option>
          <option :key="`t-${idx}`" v-for="(el, idx) in types" :value="el.value">{{ el.name }}</option>
        </select>
      </label>
      <label>
        Возраст авто
        <select v-model="form.years">
          <option disabled selected value="">Выберите значение</option>
          <option :key="`y-${idx}`" v-for="(el, idx) in years" :value="el.value">{{ el.name }}</option>
        </select>
      </label>
      <label>
        Объем двигателя (см3)
        <input :disabled="form.type === 'electric'"
               @input="(e) => form.volume = e.target.value.toString().replace(',', '.')" type="text"
               :value="form.volume">
      </label>
      <label>
        Валюта
        <select class="mr-2" v-model="form.currency">
          <option value="EUR">EUR</option>
          <option value="USD">USD</option>
          <option value="RUB">RUB</option>
          <option value="CNY">CNY</option>
        </select>
      </label>
      <label>
        Стоимость ТС

        <input @input="(e) => form.cost = e.target.value.toString().replace(',', '.')" type="text"
               :value="form.cost">


      </label>
      <button class="submit" @click="submit">Рассчитать</button>
    </div>
    <label class=" block mt-2">
      <input class="mr-2" type="checkbox" v-model="form.discount">Наличие льготы в соответствии с 140 Указом
      Президента
    </label>
    <label class=" block mt-2">
      <input class="mr-2" type="checkbox" v-model="form.export">Для вывоза в РФ
    </label>

    <div class="result" v-if="result">
      <div class="result__info">
        <p>Утильсбор: {{ result.util }} руб.</p>
        <p>Таможенный сбор: {{ result.fee }} руб.</p>
        <p>Пошлина: {{ result.duty }} руб.</p>
        <p>НДС: {{ result.tax }} руб.</p>
        <p>ИТОГО: {{ result.total }} руб.</p>
      </div>
      <div class="result__file">
        <button class="rounded  px-10 py-2 bg-blue-800" @click="writePdf">
          <i class="fas fa-file-pdf"></i> PDF
        </button>
      </div>
      <div class="result__notification">
        <p>Сумма платежей может быть увеличена таможней. Например, добавлен аукционный сбор или увеличена
          стоимость при оценке БелТПП</p>
      </div>

    </div>


  </div>
</template>
<script>

import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {roundNumber} from "@/helpers/functions";

export default {
  data: () => ({
    eurRate: 0,
    usdRate: 0,
    rubRate: 0,
    cnyRate: 0,
    form: {
      type: null,
      years: null,
      volume: null,
      cost: null,
      currency: 'EUR',
      discount: false,
      export: false
    },
    result: null,
    types: [
      {
        name: 'Электрический',
        value: 'electric'
      },
      {
        name: 'Бензин/Дизель',
        value: 'petrol'
      },
      {
        name: 'Гибрид',
        value: 'petrol'
      }
    ],
    years: [
      {
        name: 'Менее трех лет',
        value: '<3'
      },
      {
        name: 'От 3 до 5 лет',
        value: '3-5'
      },
      {
        name: 'От 5 до 7 лет',
        value: '5-7'
      },
      {
        name: 'Более 7 лет',
        value: '7<'
      }
    ],

  }),
  created() {
    this.fetchEurRate()
    this.fetchUsdRate()
    this.fetchRubRate()
    this.fetchCnyRate()
  },
  methods: {
    submit() {
      const util = this.calculateUtil() // Утиль
      const duty = this.calculateDuty() // Пошлина
      const tax = this.calculateTax() //  НДС
      const fee = this.calculateFee() // Таможенный сбор
      const total = [util, duty, tax, fee].reduce((a, b) => +a + +b, 0)
      this.result = {
        util,
        tax,
        duty,
        fee,
        total: roundNumber(total, 2)
      }
    },
    fetchEurRate() {
      axios.get('https://api.nbrb.by/exrates/rates/EUR?parammode=2').then(res => {
        this.eurRate = res.data.Cur_OfficialRate
      })
    },
    fetchUsdRate() {
      axios.get('https://api.nbrb.by/exrates/rates/USD?parammode=2').then(res => {
        this.usdRate = res.data.Cur_OfficialRate
      })
    },
    fetchRubRate() {
      axios.get('https://api.nbrb.by/exrates/rates/RUB?parammode=2').then(res => {
        this.rubRate = res.data.Cur_OfficialRate / 100
      })
    },
    fetchCnyRate() {
      axios.get('https://api.nbrb.by/exrates/rates/CNY?parammode=2').then(res => {
        this.cnyRate = res.data.Cur_OfficialRate / 10
      })
    },
    calculateFee() {
      if (this.form.type === 'electric') {
        return this.form.export ? 120.00 : 0
      }
      return 120.00
    },
    calculateTax() {
      if (this.form.type === 'electric' && ['5-7', '7<'].includes(this.form.years) && this.form.export) {
        const inByn = ((+this.getCostInEuro() + +this.getDutyForEV()) * 0.2) * this.eurRate
        const withDiscount = this.form.discount ? inByn / 2 : inByn
        return roundNumber(withDiscount, 2)
      }
      return 0
    },
    calculateDuty() {
      let duty = 0
      if (this.form.type === 'petrol') {
        switch (this.form.years) {
          case '<3':
            duty = this.getDutyFromCost();
            break;
          case '3-5':
            duty = this.getDutyFromVolume();
            break;
          case '5-7':
            duty = this.getDutyFromVolume();
            break;
          case '7<':
            duty = this.getDutyFromVolume();
            break;
          default:
            duty = 0
        }
      }
      if (this.form.type === 'electric') {
        duty = this.getDutyForEV()
      }
      const inByn = duty * this.eurRate

      if (this.form.discount) {
        const withDiscount = roundNumber(inByn, 2) / 2
        return roundNumber(withDiscount, 2)
      }
      return roundNumber(inByn, 2)
    },
    getDutyForEV() {
      return this.form.export ? this.getCostInEuro() * 0.15 : 0
    },
    getDutyFromVolume() {
      const lessFiveYears = this.form.years === '3-5'
      const volume = Number(this.form.volume)
      let rate = 0
      if (volume <= 1000) {
        rate = lessFiveYears ? 1.5 : 3
      } else if (volume > 1000 && volume <= 1500) {
        rate = lessFiveYears ? 1.7 : 3.2
      } else if (volume > 1500 && volume <= 1800) {
        rate = lessFiveYears ? 2.5 : 3.5
      } else if (volume > 1800 && volume <= 2300) {
        rate = lessFiveYears ? 2.7 : 4.8
      } else if (volume > 2300 && volume <= 3000) {
        rate = lessFiveYears ? 3 : 5
      } else if (volume > 3000) {
        rate = lessFiveYears ? 3.6 : 5.7
      } else {
        rate = 0
      }
      return rate * volume
    },
    getCostInEuro() {
      let value;
      if (this.form.currency === 'EUR') value = this.form.cost;
      if (this.form.currency === 'USD') value = (this.usdRate / this.eurRate) * this.form.cost;
      if (this.form.currency === 'RUB') value = (this.rubRate / this.eurRate) * this.form.cost
      if (this.form.currency === 'CNY') value = (this.cnyRate / this.eurRate) * this.form.cost
      return value
    },
    getDutyFromCost() {
      const cost = this.getCostInEuro()
      let result = 0
      if (cost <= 8500) {
        const a = cost * 0.54
        const b = this.form.volume * 2.5
        result = Math.max(a, b)

      } else if (cost > 8500 && cost <= 16700) {
        const a = cost * 0.48
        const b = this.form.volume * 3.5
        result = Math.max(a, b)

      } else if (cost > 16700 && cost <= 42300) {
        const a = cost * 0.48
        const b = this.form.volume * 5.5
        result = Math.max(a, b)

      } else if (cost > 42300 && cost <= 84500) {
        const a = cost * 0.48
        const b = this.form.volume * 7.5
        result = Math.max(a, b)
      } else if (cost > 84500 && cost <= 169000) {
        const a = cost * 0.48
        const b = this.form.volume * 15
        result = Math.max(a, b)
      } else if (cost > 169000) {
        const a = cost * 0.48
        const b = this.form.volume * 20
        result = Math.max(a, b)
      } else {
        result = 0
      }
      return result
    },
    onTypeChange() {
      this.form.volume = null
    },
    calculateUtil() {
      if (this.form.type === 'electric') {
        switch (this.form.years) {
          case '<3':
            return 544.50;
          case '3-5':
            return 816.70;
          case '5-7':
            return 816.70;
          case '7<':
            return 816.70;
          default:
            return 0
        }
      } else if (this.form.type === 'petrol') {
        switch (this.form.years) {
          case '<3':
            return 544.50;
          case '3-5':
            return 816.70;
          case '5-7':
            return 816.70;
          case '7<':
            return 1225.10;
          default:
            return 0
        }
      } else {
        return 0
      }

    },
    writePdf() {
      const total = +this.result.duty + +this.result.tax;
      const result = [
        {
          text: [
            '1. ЕРИП- ТАМОЖЕННЫЕ ПЛАТЕЖИ- ТОВАРЫ ДЛЯ ЛИЧНОГО ПОЛЬЗОВАНИЯ-ЗАДОЛЖЕННОСТЬ, УПЛАТА БЕЗ ДОКУМЕНТА НАЧИСЛЕНИЯ- ЛИЦА С ПАСПОРТОМ РБ- ТАМОЖЕННЫЕ СБОРЫ - 02204 - ИДЕНТИФИКАЦИОННЫЙ НОМЕР ( БУКВЫ ЛАТИНСКИЕ ЗАГЛАВНЫЕ) - ДАННЫЕ ФИО ВЛАДЕЛЬЦА- СУММА ',
            {
              text: this.result.fee,
              bold: true,
            },
            ' рублей.'
          ]
        },
        ' ',
        {
          text: [
            `2. ЕРИП- ТАМОЖЕННЫЕ ПЛАТЕЖИ- ТОВАРЫ ДЛЯ ЛИЧНОГО ПОЛЬЗОВАНИЯ-ЗАДОЛЖЕННОСТЬ, УПЛАТА БЕЗ ДОКУМЕНТА НАЧИСЛЕНИЯ- ЛИЦА С ПАСПОРТОМ РБ- ТАМОЖЕННАЯ ПОШЛИНА  - 02201 - ИДЕНТИФИКАЦИОННЫЙ НОМЕР ( БУКВЫ ЛАТИНСКИЕ ЗАГЛАВНЫЕ) - ДАННЫЕ ФИО ВЛАДЕЛЬЦА- СУММА `,
            {
              text: total,
              bold: true
            }, ` рублей.`,

          ]
        },
        ' ',
        {
          text: [
            `3. ЕРИП- ТАМОЖЕННЫЕ ПЛАТЕЖИ- УТИЛИЗАЦИОННОЙ СБОР - ЛИЦА С ПАСПОРТОМ РБ- 02013 - ИДЕНТИФИКАЦИОННЫЙ НОМЕР ( БУКВЫ ЛАТИНСКИЕ ЗАГЛАВНЫЕ) - ФИО - СУММА `,
            {
              text: this.result.util,
              bold: true
            }, ` рублей.`
          ]
        }

      ]


      var docDefinition = {
        pageOrientation: 'portrait',
        pageSize: 'A4',
        pageMargins: [40, 60, 40, 60],
        content: result
      };
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(docDefinition).open();
    }
  }

}
</script>

<style scoped>

.submit {
  @apply bg-blue-500 rounded px-4 py-3 h-11
}

input[type=text], select {
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent
  rounded bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  focus:outline-none dark:text-gray-300
  dark:focus:text-white;
}

.result {
  @apply mt-10 rounded dark:bg-gray-700 bg-gray-100 p-5 flex sm:flex-col lg:flex-row
}

.result div {
  @apply sm:w-full lg:w-1/3
}

.result__notification {
  @apply bg-red-800 text-white p-2 rounded flex items-center text-center
}

.result p {
  @apply pb-2
}
</style>