<template>
  <div class="lg:m-10 my-10 mx-2">

    <div class="my-10">
      <PaymentsCalculator/>
    </div>
    <div>
      <Accordion class="mb-4">
        <template v-slot:title>
          <span class="mb-3 font-semibold text-xl"
            >Запрашиваемые с 07.07.2023 документы, подтверждающие наличие у
            клиента (представляемого лица) статуса:</span
          >
        </template>
        <template v-slot:content>
        
            <b
              >у родителя (усыновителя, удочерителя) в многодетной семье
              (доверенного лица) запрашиваются:
            </b>
            <p>- оригинал удостоверения многодетной семьи (либо нотариально
            заверенная копия, сроком заверения не более 15 календарных дней до
            момента обращения) и копия данного документа;</p>
            <p>- если удостоверение выдано полной многодетной семье (где указаны оба родителя, один из которых выступает декларантом), дополнительно запрашиваются:</p>
            <p>- оригинал свидетельства о заключении брака родителей в многодетной семье, которые указаны в удостоверении (либо нотариально заверенная
            копия, сроком заверения не более 15 календарных дней до момента обращения) и копия данного документа;</p>
            <p>- паспорт родителя на бумажном носителе (в случае его выдачи на бумажном носителе), выступающего декларантом транспортного средства, со штампом о регистрации
            заключения брака, о заключении которого предоставлено свидетельство;</p>
            <p>- копию решения суда о расторжении брака, в котором разрешен вопрос о проживании и воспитании детей – в случае если декларантом транспортного средства является родитель, брак в многодетной семье
            которого расторгнут. Согласно данного решения должно быть подтверждено, что после расторжения брака дети находятся на
            иждивении родителя – декларанта;</p>
            <b>у инвалида I или II группы
            (доверенного лица) запрашиваются:</b>
            <p>- оригинал удостоверения инвалида (либо нотариально заверенная копия, сроком заверения не более 15
            календарных дней до момента обращения) и копия данного документа;</p>
            <b> у родителя (усыновителя, удочерителя) ребенка-инвалида в возрасте до 18 лет (доверенного лица) запрашиваются:</b>
            <p>- оригинал удостоверения инвалида (либо нотариально заверенная копия, сроком заверения не более 15 календарных дней до момента обращения) и копия данного документа;</p>
            <p>- оригинал свидетельства о рождении ребенка (либо нотариально заверенная копия, сроком заверения не более 15 календарных дней до момента обращения) и копия данного документа;</p>
            <p>- копию решения (выписки из решения) суда об усыновлении (удочерении) - для усыновителя (удочерителя), не указанного в качестве родителя в свидетельстве о рождении ребенка;</p>
            <b>у опекуна (попечителя) ребенка-инвалида в возрасте до 18 лет (доверенного лица) запрашиваются:</b>
            <p>- оригинал удостоверения инвалида (либо нотариально заверенная копия, сроком заверения не более 15 календарных дней до момента обращения) и копия данного документа;</p>
            <p>- оригинал удостоверения на право представления интересов подопечного (либо нотариально заверенная копия, сроком заверения не более 15 календарных дней до момента обращения) и копия данного документа.</p>
            <b>В отношении следующих марок и моделей транспортных средств необходимо предоставить справку от дилера о рабочем объеме двигателя:</b>
            <p>- FORD FUSION; FORD ESCAPE; DODGE CHALLENGER; JEEP GRANG CHEROKEE; CHRYSLER PACIFICA; BUICK ENCORE; FORD MUSTANG, FORD FIESTA, FORD EDGE, DODGE JOURNEY, KIA SORENTO, JEEP RENEGADE.</p>
        </template>
      </Accordion>
    </div>
    <div>
      <router-link class="bg-teal-500 rounded px-4 py-3" :to="{name:'TransportDeclarationApplication'}">Создать заявку
      </router-link>
    </div>
    <div class="my-10">
      <table class="hidden lg:table">
        <thead>
        <tr>
          <th>ID</th>
          <th>Дата</th>
          <th>Статус</th>
          <th>Исполнитель</th>
          <th>Комментарий</th>
          <th>Действие</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!applicationsList.length">
          <td class="text-center text-teal-500" colspan="6">Заявки отсутствуют</td>
        </tr>
        <tr :key="`app-${index}`" v-for="(application, index) in applicationsList">
            <td>ТА{{ application.id}}</td>
            <td>{{ application.date }}</td>
            <td>{{ application.originalStatus | capitalize }}</td>
            <template v-if="application.hasPerformer">
              <td>
              <span>
                {{ application.web_declarant.user_position | capitalize }} {{ application.web_declarant.first_name }} {{application.web_declarant.middle_name}}<br>
              </span>
<!--                <span v-if="application.phone">-->
<!--                 Телефон: {{ application.phone }}<br>-->
<!--              </span>-->
<!--                <span v-if="application.email">Email: {{ application.email }}</span>-->
              </td>
            </template>
            <template v-else>
              <td>Отсутствует</td>
            </template>

            <td>{{application.originalMessage}}</td>
            <td width="300px">
              <div class="flex justify-between">
                <button v-if="application.id"  @click="show(application.id)" class="bg-gray-500 text-white rounded px-1 py-1">
                  Просмотр
                </button>
                <button v-if="application.id && application.declarationId" @click="edit(application.id, application.declarationId)" class="bg-blue-500 text-white rounded px-1 py-1">
                  Дополнить
                </button>
                <button v-if="application.id && !application.declarationId" @click="pay(application.id)" class="bg-yellow-500  text-white rounded px-1 py-1">
                   Оплатить
                </button>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
      <div class="block lg:hidden">
        <div class="text-center text-teal-500 my-20" v-if="!applicationsList.length">Заявки отсутствуют</div>
        <div :key="`card-${index}`" v-for="(application, index) in applicationsList"
             class="rounded dark:bg-gray-800 bg-gray-100 px-2 py-2 my-5">
          <div class="flex justify-between">
            <div class="w-1/2">
              ID
            </div>
            <div class="w-1/2 text-right">
              ТА{{ application.originalId }}
            </div>
          </div>
          <div class="flex justify-between">
            <div class="w-1/2">
              Дата
            </div>
            <div class="w-1/2 text-right">
              {{ application.date }}
            </div>
          </div>
          <div class="flex justify-between">
            <div class="w-1/2">
              Статус
            </div>
            <div class="w-1/2 text-right">
              {{ application.status | capitalize }}
            </div>
          </div>
          <div class="flex justify-between">
            <div class="w-1/2">
              Исполнитель
            </div>
            <div class="w-1/2 text-right" v-if="application.hasPerformer">
              <span v-if="application.user_position">{{application.web_declarant.user_position}}<br></span>
              <span v-if="application.first_name">{{application.web_declarant.first_name}}<br></span>
              <span v-if="application.middle_name">{{application.web_declarant.middle_name}}<br></span>
<!--              <span v-if="application.phone">{{application.phone}}<br></span>-->
<!--              <span v-if="application.email">{{application.email}}<br></span>-->
            </div>
            <div class="w-1/2 text-right" v-else>

            </div>
          </div>

          <div class="m-1">
<!--            <button @click="getPaymentDocuments(application.id)" class="bg-blue-300 text-white rounded px-3 py-1"-->
<!--                    v-if="application.paymentDocuments">-->
<!--              Скачать платежное поручение-->
<!--            </button>-->

             <button v-if="application.id" @click="show(application.id)" class="bg-gray-500 text-white rounded px-3 py-1">
               Просмотр
             </button>
            <button v-if="application.id && application.declarationId" @click="edit(application.id, application.declarationId)" class="bg-blue-500 text-white rounded px-3 py-1">
              Дополнить
            </button>
            <button v-if="application.id && !application.declarationId" @click="pay(application.id)" class="bg-yellow-500 text-white rounded px-3 py-1">
              Оплатить
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import moment from "moment";
import PaymentsCalculator from "@/components/user/PaymentsCalculator.vue";
import Accordion from "@/components/Accordion.vue";

export default {
  components: {PaymentsCalculator, Accordion},
  data: () => ({
    applicationsList: []
  }),
  created() {
    this.getApplications()
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
  },
  methods: {
    show(id){
      this.$router.push({
        name:'TransportDeclarationDetail', params:{
          id
        }
      })
    },
    edit(id, declaration_id){
      this.$router.push({
        name: 'TransportDeclarationEdit', params: {
          id, declaration_id
        }
      })
    },
    pay(id){
      this.$router.push({
        name: 'TransportDeclarationPayment', params: {
          id
        }
      })
    },
    getPaymentDocuments(id) {
      axios.get(`https://web.declarant.by/api/sd/dtOrder/downloadDocuments?declarationId=${id}`, {responseType: 'blob'}).then(res => {
        if (res.data.size < 1) return this.$notify({
          title: 'Ошибка',
          text: 'Отсутствуют документы',
          type: 'error'
        })
        this.downloadFile({data: res.data, type: 'application/zip'})
      })
    },
    downloadFile({data, type, name = ""}) {
      const blob = new Blob([data], {type})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      if (name) {
        link.download = name
      }
      if (type === 'application/pdf') link.target = '_blank';
      link.click()
      setTimeout(() => URL.revokeObjectURL(link.href), 10000);
    },
    getApplications() {
      axios.get(`https://declarant.by/rest/user/transport-declaration/list`)
          .then((res) => {
            this.applicationsList = res.data?.data?.map(item => {
              const {last_name, first_name, email, user_position, phone, creation_date, declaration_id} = item?.web_declarant ?? {}
              return {
                ...item,
                declarationId: declaration_id,
                originalId: item?.original?.id ?? null,
                originalStatus: item?.uf_status ?? null,
                originalMessage: item?.uf_message ?? null,
                hasPerformer: !![last_name, first_name, email, user_position, phone].join('').length,
                date: moment(creation_date).isValid() ? moment(creation_date).format("DD.MM.YYYY") : 'Отсутсвует'
              }
            }) ?? []
          }).catch((e) => {
            console.log(e)
        this.$notify({
          title: 'Ошибка',
          text: 'Не удалось получить информацию по заявкам',
          type: 'error'
        })
      })
    }
  },
  filters: {
    capitalize(value) {
      if (value) {
        return value.toString().charAt(0).toUpperCase() + value.toString().slice(1)
      }
      return ''
    }
  }
}
</script>
<style scoped>
.table-wrapper {
  @apply my-10 overflow-y-scroll max-h-128  shadow-md
}

table {
  @apply text-left w-full border-collapse  dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm
}

thead {
  @apply dark:bg-gray-800 bg-white text-gray-500
}

th {
  @apply p-3 select-none
}

tbody {
  @apply dark:text-gray-400 text-gray-600
}

tr {
  @apply dark:bg-gray-800 bg-white  dark:hover:bg-gray-700 hover:bg-gray-300
}

td {
  @apply p-3
}

td:has(button) {
  @apply py-0
}

::-webkit-scrollbar {
  display: block;
  overflow: auto;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: lightskyblue;
}
</style>